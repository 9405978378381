html {
  height: 100%;
}

body {
  background: none;
  background-position: 380px top;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-family: 'Barlow', sans-serif;
}

body.user-space {
  background: none;
  background-position: right top;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}

.front .container-wrapper .front-wrapper {
  background-color: #ffffff;
  width: 100%;
}

.front .container-wrapper .connexion-wrapper {
  background-color: #eeecf5;
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: .25rem;
}

.front .container-wrapper .connexion-wrapper a,
.front .container-wrapper .connexion-wrapper button {
  display: flex;
  justify-content: center;
  text-decoration: none;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  margin: 0 !important;
}

.front .container-wrapper .connexion-wrapper .or {
  font-size: 1.5rem;
  color: #2f1f87;
  margin-top: 15px;
  margin-bottom: 15px;
}

.front .container-wrapper .connexion-wrapper form {
  width: 100%;
  max-width: 260px;
}

.front .container-wrapper .connexion-wrapper form label {
  font-size: 18px;
  color: #7C8389;
}

.front .container-wrapper .connexion-wrapper form .input-wrapper {
  margin-bottom: 20px;
}

.front .container-wrapper .connexion-wrapper form .input-wrapper > input {
  width: 100%;
}

.front .container-wrapper .connexion-wrapper form .input-wrapper input {
  border: 1px solid #ced4da;
}

.front .container-wrapper .connexion-wrapper form .form-text {
  color: #7C8389;
}

.front .container-wrapper .connexion-wrapper form .btn-primary {
  margin-top: 20px !important;
  width: 100%;
  max-width: 260px;
}

.front .container-wrapper .connexion-wrapper form .link {
  margin-top: 20px !important;
  color: #2F1F87;
  text-decoration: underline;
}

.front .container-wrapper .connexion-wrapper form .rgpd {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}

.front .container-wrapper .connexion-wrapper form .rgpd input {
  margin-top: 5px;
  margin-right: 5px;
}

.front .container-wrapper .connexion-wrapper form .rgpd label {
  font-size: 14px;
  margin-bottom: 0;
}

.front .container-wrapper ul {
  padding-left: 20px;
}

.front .container-wrapper ul li {
  color: #2f1f87;
  margin-bottom: 5px;
}

.front .container-wrapper ul li a {
  color: #2f1f87;
}

.front .container-wrapper .alert-info {
  color: #2f1f87;
  background-color: #eeecf5;
  border: 0;
}

.front .container-wrapper .alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.front .container-wrapper .alert h4 {
  color: inherit;
}

.front .container-wrapper .btn-primary {
  color: #fff;
  background-color: #2f1f87;
  border-radius: 4px;
  border: 0;
  transition: all 0.3s ease-in-out;
  width: 100%;
  max-width: 260px;
}

.front .container-wrapper .btn-primary:hover,
.front .container-wrapper .btn-primary:active,
.front .container-wrapper .btn-primary:focus {
  color: #fff;
  background-color: #2f1f87 !important;
  -webkit-box-shadow: 5px 5px 15px 5px #a3a2aa !important;
  box-shadow: 5px 5px 15px 5px #a3a2aa !important;
  border: 0 !important;
}

.front .container-wrapper .btn-secondary {
  color: #fff;
  background-color: #DF0345;
  border-radius: 4px;
  border: 0;
  transition: all 0.3s ease-in-out;
  width: 100%;
  max-width: 260px;
}

.front .container-wrapper .btn-secondary:hover,
.front .container-wrapper .btn-secondary:active,
.front .container-wrapper .btn-secondary:focus {
  color: #fff;
  background-color: #DF0345 !important;
  -webkit-box-shadow: 5px 5px 15px 5px #a3a2aa !important;
  box-shadow: 5px 5px 15px 5px #a3a2aa !important;
  border: 0 !important;
}

.front .container-wrapper .share {
  width: 100%;
  padding: 100px 0;
}

.front h1 {
  text-align: center;
  margin: 30px 0;
  color: #2f1f87;
  font-size: 1.5rem;
}

@media (min-width: 768px) {
  .front h1 {
    font-size: 2.5rem;
  }
}

.front h1 span {
  display: block;
  font-size: 1rem;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #7C8389;
}

@media (min-width: 768px) {
  .front h1 span {
    font-size: 1.5rem;
  }
}

.front .choices {
  margin: 60px 0;
}

@media (max-width: 1480px) {
  .front .front-wrapper {
    width: 70% !important;
  }

  .front body {
    background-position: 325px top;
  }
}

@media (max-width: 1024px) {
  .front .front-wrapper {
    width: 100% !important;
  }
}

@media (min-width: 576px) {
  .front .jumbotron {
    padding: 2rem 2rem !important;
  }
}

.front .mentions-legales h4 {
  margin-top: 60px;
}

.front .space-60 {
  height: 60px;
}

.front .space-90 {
  height: 90px;
}

.front .alert {
  display: flex;
  flex-direction: column;
}

.front .alert .fa {
  display: table-cell;
  font-size: 18px;
  padding-right: 24px;
  margin-bottom: 10px;
}

.front .alert > div,
.front .alert span {
  display: table-cell;
  vertical-align: top;
}

.front .alert ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.front .alert ul li {
  font-size: 12px;
}

.front #faq .card-header {
  background-color: #eeecf5;
  border-bottom: 1px solid #2f1f87;
}

.front #faq .card-header a {
  color: #2f1f87;
}

.front #faq .card-header a[aria-expanded="true"] {
  font-weight: 500;
}

