html {
    height: 100%;
}

body {
    background: none;
    background-position: 380px top;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    font-family: 'Barlow', sans-serif;
}

body.user-space {
    background: none;
    background-position: right top;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
}

.front {
    
    .container-wrapper {

        .front-wrapper {
            background-color: #ffffff;
            width: 100%;
        }

        .connexion-wrapper {
            background-color: #eeecf5;
            padding: 50px 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: .25rem;

            a,button {
                display: flex;
                justify-content: center;
                text-decoration: none;
                align-items: center;
                font-size: 16px;
                font-weight: 500;
                margin: 0 !important;
            }

            .or {
                font-size: 1.5rem;
                color: #2f1f87;
                margin-top: 15px;
                margin-bottom: 15px;
            }

            form {
                width: 100%;
                max-width: 260px;

                label {
                    font-size: 18px;
                    color: #7C8389;
                }

                .input-wrapper {
                    margin-bottom: 20px;
                    >input {
                        width: 100%;
                    }
                    input {
                        border: 1px solid #ced4da;
                    }
                }

                .form-text {
                    color: #7C8389; 
                }

                .btn-primary {
                    margin-top: 20px !important;
                    width: 100%;
                    max-width: 260px;
                }

                .link {
                    margin-top: 20px !important;
                    color: #2F1F87;
                    text-decoration: underline;
                }

                .rgpd {
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 15px;

                    input {
                        margin-top: 5px;
                        margin-right: 5px;
                    }

                    label {
                        font-size: 14px;
                        margin-bottom: 0;
                    }
                }
            }
        }

        ul {
            padding-left: 20px;
            li {
                color: #2f1f87;
                margin-bottom: 5px;
                
                a {
                    color: #2f1f87; 
                }
            }
        }

        .alert-info {
            color: #2f1f87;
            background-color: #eeecf5;
            border: 0;
        }

        .alert-danger {
            color: #721c24;
            background-color: #f8d7da;
            border-color: #f5c6cb;
        }

        .alert h4 {
            color: inherit;
        }

        .btn-primary {
            color: #fff;
            background-color: #2f1f87;
            border-radius: 4px;
            border: 0;
            transition: all 0.3s ease-in-out;
            width: 100%;
            max-width: 260px;

            &:hover,
            &:active,
            &:focus {
                color: #fff;
                background-color: #2f1f87 !important;
                -webkit-box-shadow: 5px 5px 15px 5px #a3a2aa !important; 
                box-shadow: 5px 5px 15px 5px #a3a2aa !important;
                border: 0 !important;
            }
        }

        .btn-secondary {
            color: #fff;
            background-color: #DF0345;
            border-radius: 4px;
            border: 0;
            transition: all 0.3s ease-in-out;
            width: 100%;
            max-width: 260px;

            &:hover,
            &:active,
            &:focus {
                color: #fff;
                background-color: #DF0345 !important;
                -webkit-box-shadow: 5px 5px 15px 5px #a3a2aa !important; 
                box-shadow: 5px 5px 15px 5px #a3a2aa !important;
                border: 0 !important;
            }
        }

        .share {
            width: 100%;
            padding: 100px 0;
        }
    }

    h1 {
        text-align: center;
        margin: 30px 0;
        color: #2f1f87;
        font-size: 1.5rem;

        @media (min-width: 768px) {
            font-size: 2.5rem;
        }

        span {
            display: block;
            font-size: 1rem;
            margin-top: 20px;
            margin-bottom: 20px;
            color: #7C8389;

            @media (min-width: 768px) {
                font-size: 1.5rem;
            }
        }
    }

    .choices {
        margin: 60px 0;
    }


    @media (max-width: 1480px) {
        .front-wrapper {
            width: 70% !important;
        }

        body {
            background-position: 325px top;
        }
    }

    @media (max-width: 1024px) {
        .front-wrapper {
            width: 100% !important;
        }
    }


    @media (min-width: 576px) {
        .jumbotron {
            padding: 2rem 2rem !important;
        }
    }

    .mentions-legales {
        h4 {
            margin-top: 60px;
        }
    }

    .space-60 {
        height: 60px;
    }

    .space-90 {
        height: 90px;
    }


    .alert {
        display: flex;
        flex-direction: column;
        .fa {
            display: table-cell;
            font-size: 18px;
            padding-right: 24px;
            margin-bottom: 10px;
        }

        & > div,
        span {
            display: table-cell;
            vertical-align: top;
        }

        ul {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                font-size: 12px;
            }
        }
    }

    #faq {
        .card-header {
            background-color: #eeecf5;
            border-bottom: 1px solid #2f1f87;
            a {
                color: #2f1f87;
                &[aria-expanded="true"] {
                    font-weight: 500;
                }
            }
        }
    }
}
